import './assets/main.css'
import './assets/tooltip.css';

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { TooltipDirective } from './directives/tooltip'

import App from './App.vue'
import router from './router'

const app = createApp(App)


  
app.directive('tooltip', TooltipDirective)
app.use(createPinia())
app.use(router)

app.mount('#app')

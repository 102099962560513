<script setup>
import { onMounted } from "vue";
import { useUserStore } from "@/stores/userStore";
import { useAppStore } from "@/stores/appStore.js";
import { storeToRefs } from "pinia";
import { NAME } from "@/stores/userStore.js";

const appStore = useAppStore();
const { appUpdates } = storeToRefs(appStore);

const userStore = useUserStore();
const { loading } = storeToRefs(userStore);

const name = localStorage.getItem(NAME);

const padZero = (value) => {
  return value < 10 ? "0" + value : value;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate =
    padZero(date.getDate()) +
    "." +
    padZero(date.getMonth() + 1) +
    "." +
    date.getFullYear() +
    " " +
    padZero(date.getHours()) +
    ":" +
    padZero(date.getMinutes()) +
    ":" +
    padZero(date.getSeconds());
  return formattedDate;
};

onMounted(() => {
  console.log(NAME);
  appStore.getAppUpdates();
});
</script>

<template>
  <main>
    <div v-if="loading">Loading</div>

    <div class="py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:text-center">
          <h2 class="text-base font-semibold leading-7 text-rose-600">Logent CMS</h2>
          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Welcome back, {{ name }}!
          </p>
          <p class="mt-6 text-lg leading-8 text-gray-600">
            Prior to sending entries to LTS, it's essential to double-check that all
            values are accurately entered and no information is missing. This step ensures
            the completeness and accuracy of our submissions.
          </p>
        </div>
        <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl
            class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16"
          >
            <div class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div
                  class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-rose-600"
                >
                  <span class="material-symbols-outlined text-white">assignment</span>
                </div>
                Inbound
              </dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">
                There are 150 inbound entries that needs to be completed. 2 of the inbound
                entries have missing data.
              </dd>
            </div>
            <div class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div
                  class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-rose-600"
                >
                  <span class="material-symbols-outlined text-white">assignment</span>
                </div>
                Outbound
              </dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">
                There are 150 inbound entries that needs to be completed. 2 of the inbound
                entries have missing data.
              </dd>
            </div>
            <div class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div
                  class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600"
                >
                  <svg
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </div>
                Simple queues
              </dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">
                Quisque est vel vulputate cursus. Risus proin diam nunc commodo. Lobortis
                auctor congue commodo diam neque.
              </dd>
            </div>
            <div class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div
                  class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-rose-600"
                >
                  <span class="material-symbols-outlined text-white">update</span>
                </div>
                Latest updates
              </dt>
              <div class="max-h-80 pr-4 overflow-scroll">
                <ul class="mt-2" v-for="appUpdate in appUpdates">
                  <li class="flex gap-4 mb-4">
                    <div>
                      <span
                        class="leading-3 text-gray-600 material-symbols-outlined text-lg text-center"
                        >deployed_code_history</span
                      >
                    </div>
                    <div>
                      <span class="flex justify-between gap-4 mb-1">
                        <p class="text-sm text-gray-900 font-semibold">
                          {{ appUpdate.app }}
                        </p>
                        <p class="text-sm text-gray-600">
                          {{ formatDate(appUpdate.date) }}
                        </p>
                      </span>
                      <p class="text-sm leading-5 text-gray-600">
                        {{ appUpdate.message }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </main>
</template>

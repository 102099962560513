import { defineStore } from 'pinia'
import { ref } from 'vue'
import { NAME } from './userStore.js';
import { API_BASE_URL } from './userStore.js';
// const API_BASE_URL = 'http://localhost:8000';

// const API_BASE_URL = 'https://logent-effektivflyt.koyeb.app';
const storedName = localStorage.getItem(NAME);

const currentDate = new Date();

    // Format the date as yyyy-mm-ddThh:mm:ss
    const formattedDate =
                currentDate.getFullYear() +
                '-' +
                ('0' + (currentDate.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + currentDate.getDate()).slice(-2) +
                'T' +
                ('0' + currentDate.getHours()).slice(-2) +
                ':' +
                ('0' + currentDate.getMinutes()).slice(-2) +
                ':' +
                ('0' + currentDate.getSeconds()).slice(-2);

export const useInboundStore = defineStore('inboundStore', {
    state: () => ({
      activeTokens: ref([]),
      pageSize: localStorage.getItem('pageSize') || '2',
      pageNumber: localStorage.getItem('pageNumber') || '1',
      selectedPurchaseOrderId: localStorage.getItem('selectedPurchaseOrderId'),
      selectedPurchaseOrderNumber: localStorage.getItem('selectedPurchaseOrderNumber'),
      selectedPurchaseOrder: localStorage.getItem('selectedPurchaseOrder'),
      selectedPackages: localStorage.getItem('selectedPackages'),
      selectedPackage: localStorage.getItem('selectedPackage'),
      selectedPackageId: localStorage.getItem('selectedPackageId'),
      selectedProducts: localStorage.getItem('selectedProducts'),
      selectedProduct: localStorage.getItem('selectedProduct'),
      selectedProductId: localStorage.getItem('selectedProductId'),
      isPurchaseOrderMenuDisabled: ref(true),
      isPackageMenuDisabled: ref(true),
      isProductMenuDisabled: ref(true),
      selectedHistoryRowId : ref(null),
        showPackage: false,
        loading: false,
        loadingPart: false,
        storedName: localStorage.getItem(NAME),
        storedDeclaration: ref([]),
        storedData: ref([]),
        isSanitizing: ref(true),
        isUppercase: ref(false),
        newGeneral: {
          inbound_type: '',
          transport_id: '',
          transport_number: ''
        },
        newStatus: {
          status: '',
          name: ''
        },
        newPurchaseOrder: {
          purchase_order_number: '',
          origin: '',
          purchase_currency: '',
          package: [
              {
              package_type: 'CARTON',
              package_sku: '',
              package_qty: '1',
              package_gross_mass: '',
              package_net_mass: '',
              goods_owner: {
                id: '',
                name: '',
                address: '',
                zip_code: '',
                zip_place: '',
                international_code: '',
                tax_number: ''
              },
              products: [
                {
                  product_name: '',
                  product_number: '',
                  product_sku: '',
                  product_description: '',
                  product_purchase_price: '',
                  product_sales_price: '0',
                  variants: [
                    {
                        variant_sku: "",
                        variant_qty: "",
                        variant_gross_mass: "",
                        variant_net_mass: "",
                        variant_commodity_code: "",
                        supplement_unit_factor: "1",
                        country_of_origin: ""
                      }
                    ]
                  }
                ]
              }
            ]
            },
        updatePurchaseOrder: {
                purchase_order_number: '',
                origin: '',
                purchase_currency: ''
        },
        newPackage: {
          package_type: 'CARTON',
          package_sku: '',
          package_qty: '1',
          package_gross_mass: '',
          package_net_mass: '',
          goods_owner: {
            id: '',
            name: '',
            address: '',
            zip_code: '',
            zip_place: '',
            international_code: '',
            tax_number: ''
          },
          products: [
            {
              product_name: '',
              product_number: '',
              product_sku: '',
              product_description: '',
              product_purchase_price: '',
              product_sales_price: '0',
              variants: [
                {
                    variant_sku: "",
                    variant_qty: "",
                    variant_gross_mass: "",
                    variant_net_mass: "",
                    variant_commodity_code: "",
                    supplement_unit_factor: "1",
                    country_of_origin: ""
                  }
                ]
              }
            ]

        },
        editPackage: {
          package_type: '',
          package_sku: '',
          package_qty: '',
          package_gross_mass: '',
          package_net_mass: '',
          goods_owner: {
            id: '',
            name: '',
            address: '',
            zip_code: '',
            zip_place: '',
            international_code: '',
            tax_number: ''
          }
        },
        editTradingPartner: {
          id: '',
          name: '',
          address: '',
          zip_code: '',
          zip_place: '',
          international_code: 'select',
          tax_number: '',
        },
        editProduct: 
          {
            
              product_name: "",
              product_number: "",
              product_sku: "",
              product_description: "",
              product_purchase_price: "0",
              product_sales_price: "0",
              variants: [
                {
                    variant_sku: "",
                    variant_qty: "1",
                    variant_gross_mass: "0",
                    variant_net_mass: "0",
                    variant_commodity_code: "",
                    supplement_unit_factor: "1",
                    country_of_origin: ""
                }
              ]
            
        },
        editGoodsOwner: {
          id: '',
          name: '',
          address: '',
          zip_code: '',
          zip_place: '',
          international_code: '',
          tax_number: '',
        },
        newDeclaration: {
            name: storedName, 
            status: 'Unopened',
            added_at: formattedDate,
            inbound_type: '1',
            transport_id: '',
            transport_number: '',
            eta: '',
            agent: {
                id: '',
                name: '',
                address: '',
                zip_code: '',
                zip_place: '',
                international_code: '',
                tax_number: '',
            },
            declarant: {
                id: '',
                name: '',
                address: '',
                zip_code: '',
                zip_place: '',
                international_code: '',
                tax_number: '',
            },
            consignee: {
                id: '',
                name: '',
                address: '',
                zip_code: '',
                zip_place: '',
                international_code: '',
                tax_number: '',
            },
            supplier: {
                id: '',
                name: '',
                address: '',
                zip_code: '',
                zip_place: '',
                international_code: '',
                tax_number: '',
            },
            inbound_cargo: {
                purchase_orders: [
                    {
                        
                            // purchase_order_id: '',
                            purchase_order_number: '',
                            purchase_currency: '',
                            origin: '',
                            package: [
                                {
                                    
                                        // package_id: '',
                                        package_type: '',
                                        package_sku: '',
                                        package_qty: '',
                                        package_gross_mass: '0',
                                        package_net_mass: '0',
                                        goods_owner: {
                                            id: '',
                                            name: '',
                                            address: '',
                                            zip_code: '',
                                            zip_place: '',
                                            international_code: '',
                                            tax_number: '',
                                        },
                                        products: [
                                            {
                                                
                                                    // product_id: '',
                                                    product_name: '',
                                                    product_number: '',
                                                    product_sku: '',
                                                    product_description: '',
                                                    product_purchase_price: '0',
                                                    product_sales_price: '0',
                                                    variants: [
                                                        {
                                                            
                                                                // variant_id: '',
                                                                variant_sku: '',
                                                                variant_qty: '1',
                                                                variant_gross_mass: '0',
                                                                variant_net_mass: '0',
                                                                variant_commodity_code: '',
                                                                supplement_unit_factor: '1',
                                                                country_of_origin: ''
                                                            
                                                        }
                                                    ]
                                                
                                            }
                                        ]
                                    
                                }
                            ]
                        
                    }
                ]
            }
        },
      selectedInboundId: ref(''),
      status: ref(''),
      // selectedPurchaseOrderId: ref(''),
      tradingPartners: ref([]),
      commodityCodes: ref([]),
      selectedPackageId: ref(''),
      selectedProductId: ref(''),
      selectedVariantId: ref(''),
      inboundDeclarations: ref([]),
      inboundDeclaration: ref([]),
      purchaseOrders: ref([]),
      packages: ref([]),
      products: ref([]),
      declarations: ref([]),
      declaration: ref([])
    }),

    actions: {
      async addInboundDeclaration() {
        try {
          const response = await fetch(`${API_BASE_URL}/api/inbound-declaration/add-declaration`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.storedData),
          });
  
          if (!response.ok) {
            throw new Error('Failed to add inbound declaration');
          }
  
          const result = await response.json();
          return result;
        } catch (error) {
          console.error('Error adding inbound declaration:', error);
          throw error;
        }
      },
      
      async fetchDeclarations(page = 1, pageSize = 10, searchTerm) {
        try {
            let apiUrl = `${API_BASE_URL}/api/inbound-declaration/get-all?page=${page}&page_size=${pageSize}`;
            // Add search term if present
            if (searchTerm) {
                apiUrl += `&search_term=${searchTerm}`;
            }
            const response = await fetch(apiUrl);
            const data = await response.json();
            this.declarations = data;
        } catch (error) {
            console.error('Error fetching declarations:', error);
        }
    },
    

      async fetchDeclaration(declarationId) {
        try {
          const response = await fetch(`${API_BASE_URL}/api/inbound-declaration/get-one?declaration_id=${declarationId}`)
          const data = await response.json()
          this.declaration = data;
        } catch (error) {
          console.error('Error fetching declaration:', error)
        }
      },

      async deleteDeclaration(declarationId) {
        try {
          await fetch(`${API_BASE_URL}/api/inbound-declaration/delete-one/${declarationId}`, {
            method: 'DELETE'
          });
          return 'Declaration deleted sucessfully';
        } catch (error) {
          console.log('Error deleting declaration:', error);
          throw error;
        } finally {
          this.fetchDeclarations();
        }
      },
      
      async sendDeclaration(declarationId, email) {
        try {
            const response = await fetch(`${API_BASE_URL}/api/inbound-declaration/send-one`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    declarationId: declarationId,
                    email: email
                })
            });
    
            if (response.ok) {
                console.log('Declaration sent successfully.');
            } else {
                console.error('Failed to send declaration:', response.statusText);
            }
        } catch (error) {
            console.error('Error sending declaration:', error);
        }
    },
    
    
    
      async changePageSize(value) {
        this.pageSize = value;
        localStorage.setItem('pageSize', value);
    },

    // New 06.05.2024
    async getMdtData() {
     
  },
       async isTokenActive() {
        try {
          const response = await fetch(`${API_BASE_URL}/api/users/tokens/active`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          })
          if (response.status == 200) {
            this.activeTokens = await response.json()
            
          } else {
            const errorData = await response.json()
            console.error('Error fetching tokens', errorData.error)
          }
        } catch (error) {
          console.log('Error fetching tokens:', error.message)
        }
       },
       async getInboundDeclarations() {
        this.loading = true;
        try {
            const response = await fetch(`${API_BASE_URL}/api/inbound/get-declarations`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (response.status === 200) {
                this.inboundDeclarations = await response.json()
            } else {
                const errorData = await response.json()
                console.error('Error fetching declarations:', errorData.error)
            }
        } catch (error) {
            console.log('Error fetching declarations:', error.message)
        } finally {
            this.loading = false;
        }
    },
    
        async addDeclaration(transportId, transportNumber) {
            try {
                this.newDeclaration.transport_id = transportId;
                this.newDeclaration.transport_number = transportNumber;
                
                const response = await fetch(`${API_BASE_URL}/api/inbound/add-declaration`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.newDeclaration)
                });
                if (response.status === 200) {
                    const responseData = await response.json();
                    this.selectedInboundId = responseData.declaration_id;
                    
                    await this.getInboundDeclarations(this.pageSize, this.pageNumber);
                } else {
                    const errorData = await response.json();
                    console.error('Error adding declaration:', errorData.error);
                }
            } catch (error) {
                console.log('Error adding declaration:', error.message);
            }
        },
        async getInboundDeclarationById(declarationId) {    
            try {
                this.loading = true;
              const response = await fetch(
                `${API_BASE_URL}/api/inbound/get-declarations/${declarationId}`,
                {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json'
                  }
                }
              )
              if (response.status === 200) {
                this.inboundDeclaration = await response.json()
                
                this.selectedInboundId = declarationId
                
              } else {
                const errorData = await response.json()
                console.log('Error fetching declarations:', errorData.error)
              }
            } catch (error) {
              console.log('Error fetching declarations:', error.message)
            } finally {
                this.loading = false;
                localStorage.setItem('selectedPurchaseOrder', JSON.stringify(this.inboundDeclaration.inbound_cargo.purchase_orders[0]));
                localStorage.setItem('selectedPurchaseOrderId', this.inboundDeclaration.inbound_cargo.purchase_orders[0].purchase_order_id)
                
            }
        },
        

        async updateTradingPartner(updated_data) {
          try {

            this.editTradingPartner = updated_data;
            

            const requestData = {
              editTradingPartner: this.editTradingPartner,
              additionalData: Object.keys(this.editTradingPartner)[0]
          };
              const response = await fetch(`${API_BASE_URL}/api/inbound/update-trading-partner/${this.selectedInboundId}`, {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(this.editTradingPartner)
              });
              if (response.status === 200) {
                // FIXME This will reload the page
                  await this.getInboundDeclarationById(this.selectedInboundId)
              } else {
                  const errorData = await response.json();
                  console.error('Error adding declaration:', errorData.error);
              }
          } catch (error) {
              console.log('Error adding declaration:', error.message);
          }
      },

      async updateSupplier(updated_data) {
        try {
          const payload = {
            'supplier': updated_data
        
          }
            const response = await fetch(`${API_BASE_URL}/api/inbound/update-supplier/${this.selectedInboundId}`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });
            if (response.status === 200) {
              // FIXME This will reload the page
                await this.getInboundDeclarationById(this.selectedInboundId)
            } else {
                const errorData = await response.json();
                console.error('Error adding declaration:', errorData.error);
            }
        } catch (error) {
            console.log('Error adding declaration:', error.message);
        }
    },

        async updateStatus(name, status) {
            try {
                this.newStatus.status = status
                this.newStatus.name = name
                this.status = status
            
                const response = await fetch(`${API_BASE_URL}/api/inbound/update-status/${this.selectedInboundId}`, {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.newStatus)
                });
                if (response.status === 200) {
                    // this.purchaseOrders = await response.json();
                } else {
                    const errorData = await response.json();
                    console.error('Error adding declaration:', errorData.error);
                }
            } catch (error) {
                console.log('Error adding declaration:', error.message);
            }
        },
        async updateGeneral(updateData) {
          
          try {
              this.newGeneral = updateData;
              
              const response = await fetch(`${API_BASE_URL}/api/inbound/update-general/${this.selectedInboundId}`, {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(this.newGeneral)
              });
              if (response.status === 200) {
                  // this.purchaseOrders = await response.json();
              } else {
                  const errorData = await response.json();
                  console.error('Error adding declaration:', errorData.error);
              }
          } catch (error) {
              console.log('Error adding declaration:', error.message);
          }
      },

      async deleteInboundDeclarations(declarationIds) {
        try {
            const response = await fetch(
                `${API_BASE_URL}/api/inbound/delete-declarations`, // Modify the endpoint URL
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(declarationIds) // Pass the list of IDs as the request body
                }
            )
    
            if (response.ok) {
                // await this.getInboundDeclarations() // Refresh the list after deleting declarations
            } else {
                const errorData = await response.json()
                console.error('Error deleting declarations:', errorData)
            }
        } catch (error) {
            console.error('Error deleting declarations:', error)
        }
    },
    

          async getPurchaseOrders() {
            try {
                const response = await fetch(`${API_BASE_URL}/api/inbound/get-declarations/${this.selectedInboundId}/purchase-orders`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                if (response.status === 200) {
                    this.purchaseOrders = await response.json()
                    if (this.purchaseOrders.length > 0) {
                        // Get the last element of the array
                        const lastPurchaseOrder = this.purchaseOrders[this.purchaseOrders.length - 1];
                        // Access the purchase_order_id property of the last element
                        const lastPurchaseOrderId = lastPurchaseOrder.purchase_order_id;
                        // Print the last purchase_order_id
                        
                        this.selectedPurchaseOrderId = lastPurchaseOrderId
                    } else {
                        
                    }
                    // Check if the array is not empty
                    
                    // this.selectedPurchaseOrderId = this.purchaseOrders[0].purchase_order_id
                } else {
                    const errorData = await response.json()
                    console.error('Error fetching declarations:', errorData.error)
                }
            } catch (error) {
                console.log('Error fetching declarations:', error.message)
            } finally {
                
            }
        },
        async addPurchaseOrderOld() {
            
          try {
              const response = await fetch(`${API_BASE_URL}/api/inbound/get-declarations/${this.selectedInboundId}/purchase_order`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(this.newPurchaseOrder)
              });
              if (response.status === 200) {
                  // this.purchaseOrders = await response.json();
                  
                  await this.getInboundDeclarationById(this.selectedInboundId)
              } else {
                  const errorData = await response.json();
                  console.error('Error adding declaration:', errorData.error);
              }
          } catch (error) {
              console.log('Error adding declaration:', error.message);
          } finally {
          }
      },
        async addPurchaseOrder(newPurchaseOrderNumber, newPurchaseOrderCurrency, newPurchaseOrderOrigin, newPackageType, newPackageSku, newPackageQuantity, newPackageGrossWeight, newPackageNetWeight) {
            try {

                this.newPurchaseOrder.purchase_order_number = newPurchaseOrderNumber;
                this.newPurchaseOrder.purchase_currency = newPurchaseOrderCurrency;
                this.newPurchaseOrder.origin = newPurchaseOrderOrigin;
                this.newPurchaseOrder.package[0].package_type = newPackageType;
                this.newPurchaseOrder.package[0].package_sku = newPackageSku;
                this.newPurchaseOrder.package[0].package_qty = newPackageQuantity;
                this.newPurchaseOrder.package[0].package_gross_mass = newPackageGrossWeight;
                this.newPurchaseOrder.package[0].package_net_mass = newPackageNetWeight;

                const response = await fetch(`${API_BASE_URL}/api/inbound/get-declarations/${this.selectedInboundId}/purchase_order`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.newPurchaseOrder)
                });

                if (response.status === 200) {
                    // this.purchaseOrders = await response.json();
                    const responseData = await response.json();
                    this.selectedPurchaseOrderId = responseData.purchase_order.purchase_order_id;
                    this.selectedPackages = responseData.purchase_order.package;
                    localStorage.setItem('selectedPurchaseOrderId', this.selectedPurchaseOrderId)
                    localStorage.setItem('selectedPackages', JSON.stringify(this.selectedPackages));

                    
                    
                } else {
                    const errorData = await response.json();
                    console.error('Error adding declaration:', errorData.error);
                }
            } catch (error) {
                console.log('Error adding declaration:', error.message);
            } finally {
              await this.getInboundDeclarationById(this.selectedInboundId)
            }
        },

        async deletePurchaseOrder() {
            try {
              const response = await fetch(
                `${API_BASE_URL}/api/inbound/get-declarations/${this.selectedInboundId}/purchase-orders/${this.selectedPurchaseOrderId}`,
                {
                  method: 'DELETE',
                  headers: {
                    'Content-Type': 'application/json'
                  }
                }
              )
      
              if (response.status === 200) {
                await this.getInboundDeclarationById(this.selectedInboundId)
                this.selectedPurchaseOrderId = null;
                this.selectedPurchaseOrder = null;
                 // Refresh the list after deleting a declaration
              } else {
                const errorData = await response.json()
                console.error('Error deleting declaration:', errorData.error)
              }
            } catch (error) {
              console.error('Error deleting declaration:', error.message)
            } finally {
              localStorage.removeItem('selectedPurchaseOrderId')
              localStorage.removeItem('selectedPurchaseOrder')
            }
          },

        async updatePurchaseOrders(purchaseOrder) {
            try {
                this.updatePurchaseOrder.purchase_order_number = purchaseOrder.purchase_order_number
                this.updatePurchaseOrder.origin = purchaseOrder.origin
                this.updatePurchaseOrder.purchase_currency = purchaseOrder.purchase_currency
                
                const response = await fetch(`${API_BASE_URL}/api/inbound/update-purchase-order/${this.selectedInboundId}/purchase-order/${this.selectedPurchaseOrderId}`, {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.updatePurchaseOrder)
                });
                if (response.status === 200) {
                    // this.purchaseOrders = await response.json();
                    await this.getInboundDeclarationById(this.selectedInboundId)
                    
                    
                } else {
                    const errorData = await response.json();
                    console.error('Error adding declaration:', errorData.error);
                }
            } catch (error) {
                console.log('Error adding declaration:', error.message);
            }
        },

        async updatePackage(pkg) {
          try {

              this.editPackage = pkg

            
              
              const response = await fetch(`${API_BASE_URL}/api/inbound/get-declarations/${this.selectedInboundId}/purchase-orders/${this.selectedPurchaseOrderId}/packages/${this.selectedPackageId} `, {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(this.editPackage)
              });
              if (response.status === 200) {
                const responseData = await response.json();

                this.selectedPurchaseOrderId = responseData.purchase_order.purchase_order_id;
                this.selectedPackages = responseData.purchase_order.package;
                localStorage.setItem('selectedPurchaseOrderId', this.selectedPurchaseOrderId)
                localStorage.setItem('selectedPackages', JSON.stringify(this.selectedPackages));

                  // this.purchaseOrders = await response.json();
                  
                  // await this.getPurchaseOrders(this.selectedInboundId);
                  

              } else {
                  const errorData = await response.json();
                  console.error('Error adding declaration:', errorData.error);
              }
          } catch (error) {
              console.log('Error adding declaration:', error.message);
          } finally {
            await this.getInboundDeclarationById(this.selectedInboundId)
          }
      },

        async getPackages() {
            try {
                const response = await fetch(`${API_BASE_URL}/api/inbound/get-declarations/${this.selectedInboundId}/purchase-orders/${this.selectedPurchaseOrderId}/packages`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                if (response.status === 200) {
                    this.packages = await response.json()
                    
                } else {
                    const errorData = await response.json()
                    console.error('Error fetching declarations:', errorData.error)
                }
            } catch (error) {
                console.log('Error fetching declarations:', error.message)
            }
        },
        async addPackageOld() {
            
          try {
              const response = await fetch(`${API_BASE_URL}/api/inbound/add-package/${this.selectedInboundId}/packages/${this.selectedPurchaseOrderId}`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(this.newPackage)
              });
              if (response.status === 200) {
                  // this.purchaseOrders = await response.json();
                  
                  await this.getInboundDeclarationById(this.selectedInboundId)
    

              } else {
                  const errorData = await response.json();
                  console.error('Error adding declaration:', errorData.error);
              }
          } catch (error) {
              console.log('Error adding declaration:', error.message);
          } finally {
          }
      },
        async addPackage(newPackageType, newPackageSku, newPackageQuantity, newPackageGrossWeight, newPackageNetWeight) {
          try {
            this.newPackage.package_type = newPackageType;
            this.newPackage.package_sku = newPackageSku;
            this.newPackage.package_qty = newPackageQuantity;
            this.newPackage.package_gross_mass = newPackageGrossWeight;
            this.newPackage.package_net_mass = newPackageNetWeight;
              const response = await fetch(`${API_BASE_URL}/api/inbound/add-package/${this.selectedInboundId}/packages/${this.selectedPurchaseOrderId}`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(this.newPackage)
              });
              if (response.status === 200) {
                const responseData = await response.json();
                this.selectedPurchaseOrderId = responseData.purchase_order.purchase_order_id;
                this.selectedPackages = responseData.purchase_order.package;
                localStorage.setItem('selectedPurchaseOrderId', this.selectedPurchaseOrderId)
                localStorage.setItem('selectedPackages', JSON.stringify(this.selectedPackages));
              
                  // this.purchaseOrders = await response.json();
                  
                  // await this.getInboundDeclarationById(this.selectedInboundId)
                  // console.log(this.selectedPurchaseOrderId)

              } else {
                  const errorData = await response.json();
                  console.error('Error adding declaration:', errorData.error);
              }
          } catch (error) {
              console.log('Error adding declaration:', error.message);
          } finally {
            await this.getInboundDeclarationById(this.selectedInboundId)
          }
      },

      async updateProduct(product) {
        try {
            this.editProduct = product
            
            
            const response = await fetch(`${API_BASE_URL}/api/inbound/update-product/${this.selectedInboundId}/purchase_orders/${this.selectedPurchaseOrderId}/packages/${this.selectedPackageId}/product/${this.selectedProductId} `, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.editProduct)
            });
            if (response.status === 200) {
                // this.purchaseOrders = await response.json();
                
                // await this.getPurchaseOrders(this.selectedInboundId);
            } else {
                const errorData = await response.json();
                console.error('Error adding declaration:', errorData.error);
            }
        } catch (error) {
            console.log('Error adding declaration:', error.message);
        }
    },

    async updateVariant(variant) {
      try {
          const response = await fetch(`${API_BASE_URL}/api/inbound/update-variant/${this.selectedInboundId}/purchase_orders/${this.selectedPurchaseOrderId}/packages/${this.selectedPackageId}/product/${this.selectedProductId}/variant/${this.selectedVariantId}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(variant)
          });
          if (response.status === 200) {
              // this.purchaseOrders = await response.json();
              
              // await this.getPurchaseOrders(this.selectedInboundId);
          } else {
              const errorData = await response.json();
              console.error('Error adding declaration:', errorData.error);
          }
      } catch (error) {
          console.log('Error adding declaration:', error.message);
      }
  },
      
      async addProduct() {
            
        try {
            const response = await fetch(`${API_BASE_URL}/api/inbound/add-product/${this.selectedInboundId}/purchase_orders/${this.selectedPurchaseOrderId}/packages/${this.selectedPackageId}`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.editProduct)
            });
            if (response.status === 200) {
                // this.purchaseOrders = await response.json();
                
                this.getInboundDeclarationById(this.selectedInboundId)

            } else {
                const errorData = await response.json();
                console.error('Error adding declaration:', errorData.error);
            }
        } catch (error) {
            console.log('Error adding declaration:', error.message);
        } finally {
        }
    },

      async deletePackage() {
        try {
          const response = await fetch(
            `${API_BASE_URL}/api/inbound/delete-package/${this.selectedInboundId}/purchase_orders/${this.selectedPurchaseOrderId}/packages/${this.selectedPackageId}`,
            {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
  
          if (response.status === 200) {
            this.getInboundDeclarationById(this.selectedInboundId)
             // Refresh the list after deleting a declaration
          } else {
            const errorData = await response.json()
            console.error('Error deleting declaration:', errorData.error)
          }
        } catch (error) {
          console.error('Error deleting declaration:', error.message)
        }
      },

      async getProducts() {
        try {
            const response = await fetch(`${API_BASE_URL}/api/inbound/products/${this.selectedInboundId}/${this.selectedPurchaseOrderId}/${this.selectedPackageId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (response.status === 200) {
                this.products = await response.json()
                
            } else {
                const errorData = await response.json()
                console.error('Error fetching declarations:', errorData.error)
            }
        } catch (error) {
            console.log('Error fetching declarations:', error.message)
        }
    },

    async deleteProduct() {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/inbound/delete-product/${this.selectedInboundId}/purchase_orders/${this.selectedPurchaseOrderId}/packages/${this.selectedPackageId}/products/${this.selectedProductId}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )

        if (response.status === 200) {
          this.getInboundDeclarationById(this.selectedInboundId)
           // Refresh the list after deleting a declaration
        } else {
          const errorData = await response.json()
          console.error('Error deleting declaration:', errorData.error)
        }
      } catch (error) {
        console.error('Error deleting declaration:', error.message)
      }
    },

        async updateGoodsOwner(tradingPartner) {
          try {
            
              this.editGoodsOwner = tradingPartner
              
              
              const response = await fetch(`${API_BASE_URL}/api/inbound/goods-owner/${this.selectedInboundId}/purchase-order/${this.selectedPurchaseOrderId}/package/${this.selectedPackageId}`, {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(this.editGoodsOwner)
              });
              if (response.status === 200) {
                  // this.purchaseOrders = await response.json();
                  await this.getInboundDeclarationById(this.selectedInboundId)
                  
                  // await this.getPurchaseOrders(this.selectedInboundId);
              } else {
                  const errorData = await response.json();
                  console.error('Error adding declaration:', errorData.error);
              }
          } catch (error) {
              console.log('Error adding declaration:', error.message);
          }
      },

        async getTradingPartners() {
          try {
              const response = await fetch(`${API_BASE_URL}/internal/get-trading-partners`, {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/json'
                  }
              })
              if (response.status === 200) {
                  this.tradingPartners = await response.json()
                  
              } else {
                  const errorData = await response.json()
                  console.error('Error fetching declarations:', errorData.error)
              }
          } catch (error) {
              console.log('Error fetching declarations:', error.message)
          } 
      },

      async getCommodityCodes(search) {
        try {
            const response = await fetch(`${API_BASE_URL}/internal/commodity-data/${search}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (response.status === 200) {
                this.commodityCodes = await response.json()
                
            } else {
                const errorData = await response.json()
                console.error('Error fetching declarations:', errorData.error)
            }
        } catch (error) {
            console.log('Error fetching declarations:', error.message)
        }
    },

    async updateCustomDescription(commodityCode, customDescription) {
      try {
        const data = {
          name: commodityCode,
          custom_description: customDescription
      };
  
      const response = await fetch(`${API_BASE_URL}/internal/add-custom-description`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
        if (response.status === 200) {
        }
      } catch (error) {
        console.log('Error updating custom description', error.message);
      }
    },

    async exportXml() {
      
      try {
          const response = await fetch(`${API_BASE_URL}/api/inbound/create-xml/${this.selectedInboundId}`, {
              method: 'POST',
          });
  
          if (response.ok) {
              
              // Additional logic if needed
          } else {
              const errorData = await response.json();
              console.error('Error creating XML:', errorData.detail);
          }
      } catch (error) {
          console.error('Error creating XML:', error.message);
      } finally {
        alert(`XML file for: ${this.selectedInboundId} sent! `)
      }
  }
  

        


      

        
        
    }
})
export function TooltipDirective(el, binding) {
    const { text, position, delay, animation } = binding.value;

    el.setAttribute('data-tooltip', text);
    el.classList.add('with-tooltip');
    el.classList.add(`tooltip--${position}`);

    // Add event listeners for mouse hover and focus
    el.addEventListener('mouseenter', showTooltip);
    el.addEventListener('focus', showTooltip);

    el.addEventListener('mouseleave', hideTooltip);
    el.addEventListener('blur', hideTooltip);

    let timeoutId;

    function showTooltip() {
        timeoutId = setTimeout(() => {
            el.setAttribute('aria-describedby', 'tooltip');
            el.classList.add('tooltip-visible');
        }, delay || 300); // Default delay of 300ms if not specified
    }

    function hideTooltip() {
        clearTimeout(timeoutId);
        el.removeAttribute('aria-describedby');
        el.classList.remove('tooltip-visible');
    }

    // Optional: Add animation class
    if (animation) {
        el.classList.add('tooltip-animate');
    }
}

import { defineStore } from 'pinia'
import { ref } from 'vue'
import { NAME } from './userStore.js'
import { API_BASE_URL } from './userStore.js'

// FIXME this doesnt work
// const storedName = localStorage.getItem(NAME);
const storedName = "test"
// const API_BASE_URL = 'http://localhost:8000';
// const API_BASE_URL = 'https://logent-effektivflyt.koyeb.app';

const currentDate = new Date();

    // Format the date as yyyy-mm-ddThh:mm:ss
    const formattedInvoiceDate =
      currentDate.getFullYear() +
      '-' +
      ('0' + (currentDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + currentDate.getDate()).slice(-2);


// const API_BASE_URL = 'https://logent-effektivflyt.koyeb.app';
export const useOutboundStore = defineStore('outboundStore', {
    state: () => ({
      outboundType: ref(''),
        loading: false,
        loadingPart: false,
        commodityCodes: ref([]),
        storedName: localStorage.getItem(NAME),
        invoices: ref([]),
        invoiceLines: ref([]),
        tradingPartners: ref([]),
        editGeneralSection: {
          outbound_type: '',
          outbound_number: '',
          outbound_date: ''
        },

        editConsignor: {
          id: '',
          name: '',
          address: '',
          zip_code: '',
          zip_place: '',
          international_code: '',
          tax_number: '',
        },

        newInvoiceLine: {

          // invoice_line_id: "string",
          colli: "0",
          shared_cost: "N",
          products: [
            {
        
                // product_id: "string",
                product_name: "",
                product_number: "",
                product_sku: "",
                product_description: "",
                product_purchase_price: "0",
                product_sales_price: "0",
                variants: [
                  {
                    
                      // variant_id: "string",
                      variant_sku: "",
                      variant_qty: "1",
                      variant_gross_mass: "0",
                      variant_net_mass: "0",
                      variant_commodity_code: "",
                      supplement_unit_factor: "1",
                      country_of_origin: ""
                    
                  }
                ]
             
            }
          ]
},
        newStatus: {
          status: '',
          name: ''
        },
        newProduct: {
          product_name: "",
              product_number: "",
              product_sku: "",
              product_description: "",
              product_purchase_price: "0",
              product_sales_price: "0",
              variants: [
                {
                    variant_sku: "",
                    variant_qty: "1",
                    variant_gross_mass: "0",
                    variant_net_mass: "0",
                    variant_commodity_code: "",
                    supplement_unit_factor: "1",
                    country_of_origin: "select"
              
                }
              ]
        },
        editProduct: 
          {
            
              product_name: "",
              product_number: "",
              product_sku: "",
              product_description: "",
              product_purchase_price: "0",
              product_sales_price: "0",
              variants: [
                {
                  
                    variant_sku: "",
                    variant_qty: "1",
                    variant_gross_mass: "0",
                    variant_net_mass: "0",
                    variant_commodity_code: "",
                    supplement_unit_factor: "1",
                    country_of_origin: "select"
                  
                }
              ]
            
        },
        newDeclaration : {
            name: storedName, 
            status: 'Unopened',
            outbound_type: "SHIPMENT",
            outbound_number: '',
            outbound_date: '',
            invoices: [
              {
                
                  invoice_type: "INVOICE",
                  // invoice_id: "string",
                  invoice_number: "",
                  invoice_date: '',
                  invoice_amount: "0",
                  invoice_currency: "EUR",
                  delivery_term: "FOB",
                  bill_to: {
                    id: "",
                    name: "",
                    address: "",
                    zip_code: "",
                    zip_place: "",
                    international_code: "",
                    tax_number: ""
                  },
                  ship_to: {
                    id: "",
                    name: "",
                    address: "",
                    zip_code: "",
                    zip_place: "",
                    international_code: "",
                    tax_number: ""
                  },
                  consignor: {
                    id: "",
                    name: "",
                    address: "",
                    zip_code: "",
                    zip_place: "",
                    international_code: "",
                    tax_number: ""
                  },
                  invoice_lines: [
                    {
                      
                        // invoice_line_id: "string",
                        colli: "0",
                        shared_cost: "N",
                        products: [
                          {
                            
                              // product_id: "string",
                              product_name: "",
                              product_number: "",
                              product_sku: "",
                              product_description: "",
                              product_purchase_price: "0",
                              product_sales_price: "0",
                              variants: [
                                {
                                  
                                    // variant_id: "string",
                                    variant_sku: "",
                                    variant_qty: "1",
                                    variant_gross_mass: "0",
                                    variant_net_mass: "0",
                                    variant_commodity_code: "",
                                    supplement_unit_factor: "1",
                                    country_of_origin: ""
                                  
                                }
                              ]
                            }
                          
                        ]
                      }
                    
                  ]
                }
              
            ]
          },

          editInvoice: {
                invoice_number: '',
                invoice_type: '',
                invoice_date: '',
                invoice_amount: '',
                invoice_currency: '',
                delivery_term: '',
                bill_to: {
                  id: '',
                  name: '',
                  address: '',
                  zip_code: '',
                  zip_place: '',
                  international_code: '',
                  tax_number: '',
                },
                ship_to: {
                  id: '',
                  name: '',
                  address: '',
                  zip_code: '',
                  zip_place: '',
                  international_code: '',
                  tax_number: '',
                }
            
        },
        newInvoice : {
            invoice_type: "INVOICE",
            // invoice_id: "",
            invoice_number: "",
            invoice_date: formattedInvoiceDate,
            invoice_amount: "0",
            invoice_currency: "",
            delivery_term: "EXW",
            bill_to: {
              id: "",
              name: "",
              address: "",
              zip_code: "",
              zip_place: "",
              international_code: "",
              tax_number: ""
            },
            ship_to: {
              id: "",
              name: "",
              address: "",
              zip_code: "",
              zip_place: "",
              international_code: "",
              tax_number: ""
            },
            consignor: {
              id: "",
              name: "",
              address: "",
              zip_code: "",
              zip_place: "",
              international_code: "",
              tax_number: ""
            },
            invoice_lines: [
              {
                
                  // invoice_line_id: "string",
                  colli: "0",
                  shared_cost: "N",
                  products: [
                    {
                      
                        // product_id: "string",
                        product_name: "",
                        product_number: "",
                        product_sku: "",
                        product_description: "",
                        product_purchase_price: "0",
                        product_sales_price: "0",
                        variants: [
                          {
                            
                              // variant_id: "string",
                              variant_sku: "",
                              variant_qty: "1",
                              variant_gross_mass: "0",
                              variant_net_mass: "0",
                              variant_commodity_code: "",
                              supplement_unit_factor: '1',
                              country_of_origin: ""
                            }
                          
                        ]
                      
                    }
                  ]
                }
              
            ]
    },
      selectedOutboundId: ref(''),
      selectedInvoiceId: ref(''),
      selectedInvoiceLineId: ref(''),
      selectedProductId: ref(''),
      selectedVariantId: ref(''),
      outboundDeclaration: ref([]),
      outboundDeclarations: ref([]),
      products: ref([]),

      // New outbound
    clientData: ref([]),
    selectedHistoryRowId: ref(null),
    declaration: ref([]),
    declarations: ref([])
    }),


    actions: {
      // New Outbound
      async fetchDeclarations(page = 1, pageSize = 10, searchTerm) {
        try {
            let apiUrl = `${API_BASE_URL}/api/outbound-declaration/get-all?page=${page}&page_size=${pageSize}`;
            // Add search term if present
            if (searchTerm) {
                apiUrl += `&search_term=${searchTerm}`;
            }
            const response = await fetch(apiUrl);
            const data = await response.json();
            this.declarations = data;
        } catch (error) {
            console.error('Error fetching declarations:', error);
        }
      },

      async fetchDeclaration(declarationId) {
        try {
          const response = await fetch(`${API_BASE_URL}/api/outbound-declaration/get-one?declaration_id=${declarationId}`)
          const data = await response.json()
          this.declaration = data;
        } catch (error) {
          console.error('Error fetching declaration:', error)
        }
      },

      async fetchClientData(page = 1, pageSize = 10, searchTerm) {
        try {
            let apiUrl = `${API_BASE_URL}/api/clients/get-all?page=${page}&page_size=${pageSize}`;
            // Add search term if present
            if (searchTerm) {
                apiUrl += `&search_term=${searchTerm}`;
            }
            const response = await fetch(apiUrl);
            const data = await response.json();
            this.clientData = data;
        } catch (error) {
            console.error('Error fetching declarations:', error);
        }
    },
    // OLD OUTBOUND
        async getOutboundDeclarations() {
            try {
                const response = await fetch(`${API_BASE_URL}/api/outbound/get-declarations`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                if (response.status === 200) {
                    this.outboundDeclarations = await response.json()
                } else {
                    const errorData = await response.json()
                    console.error('Error fetching declarations:', errorData.error)
                }
            } catch (error) {
                console.log('Error fetching declarations:', error.message)
            }
        },

        async getOutboundDeclarationById(declarationId) {     
          try {
            console.log("DJKDJ")
              this.loading = true;
            const response = await fetch(
              `${API_BASE_URL}/api/outbound/get-declarations/${declarationId}`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            )
            if (response.status === 200) {
              this.outboundDeclaration = await response.json()
              this.selectedOutboundId = this.outboundDeclaration._id;
          
            } else {
              const errorData = await response.json()
              console.log('Error fetching declarations:', errorData.error)
            }
          } catch (error) {
            console.log('Error fetching declarations:', error.message)
          } finally {
              this.loading = false;
          }
      },
      async getCommodityCodes(search) {
        try {
            const response = await fetch(`${API_BASE_URL}/internal/commodity-data/${search}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (response.status === 200) {
                this.commodityCodes = await response.json()
                
            } else {
                const errorData = await response.json()
                console.error('Error fetching declarations:', errorData.error)
            }
        } catch (error) {
            console.log('Error fetching declarations:', error.message)
        }
    },
    async updateVariant(variant) {
      try {

          console.log(variant)
          
          
          const response = await fetch(`${API_BASE_URL}/api/outbound/update-variant/${this.selectedOutboundId}/invoices/${this.selectedInvoiceId}/invoice-lines/${this.selectedInvoiceLineId}/product/${this.selectedProductId}/variant/${this.selectedVariantId}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(variant)
          });
          if (response.status === 200) {
              // this.purchaseOrders = await response.json();
              
              // await this.getPurchaseOrders(this.selectedInboundId);
          } else {
              const errorData = await response.json();
              console.error('Error adding declaration:', errorData.error);
          }
      } catch (error) {
          console.log('Error adding declaration:', error.message);
      }
  },
      async updateGeneral(updateData) {
          
        try {
            this.editGeneralSection = updateData;
            
            const response = await fetch(`${API_BASE_URL}/api/outbound/update-general/${this.selectedOutboundId}`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.editGeneralSection)
            });
            if (response.status === 200) {
                // this.invoices = await response.json();
            } else {
                const errorData = await response.json();
                console.error('Error adding declaration:', errorData.error);
            }
        } catch (error) {
            console.log('Error adding declaration:', error.message);
        }
    },
    async updateStatus(name, status) {
      try {
          this.newStatus.status = status
          this.newStatus.name = name
          this.status = status
      
          const response = await fetch(`${API_BASE_URL}/api/outbound/update-status/${this.selectedOutboundId}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(this.newStatus)
          });
          if (response.status === 200) {
              // this.purchaseOrders = await response.json();
          } else {
              const errorData = await response.json();
              console.error('Error adding declaration:', errorData.error);
          }
      } catch (error) {
          console.log('Error adding declaration:', error.message);
      }
  },
      async getInvoices() {
        try {
            const response = await fetch(`${API_BASE_URL}/api/outbound/get-declarations/${this.selectedOutboundId}/invoices`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (response.status === 200) {
                this.invoices = await response.json()
                if (this.invoices.length > 0) {
                    // Get the last element of the array
                    const lastInvoice = this.invoices[this.invoices.length - 1];
                    // Access the purchase_order_id property of the last element
                    const lastInvoiceId = lastInvoice.invoice_id;
                    // Print the last purchase_order_id
                    
                    this.selectedInvoiceId = lastInvoiceId
                } else {
                    
                }
                // Check if the array is not empty
                
                // this.selectedPurchaseOrderId = this.purchaseOrders[0].purchase_order_id
            } else {
                const errorData = await response.json()
                console.error('Error fetching declarations:', errorData.error)
            }
        } catch (error) {
            console.log('Error fetching declarations:', error.message)
        } finally {
            
        }
    },

    async getInvoiceLines() {
      try {
          const response = await fetch(`${API_BASE_URL}/api/outbound/get-declarations/${selectedOutboundId}/invoices/${selectedInvoiceId}/invoice-lines'`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if (response.status === 200) {
            this.invoiceLines = await response.json()
            
        } else {
            const errorData = await response.json()
            console.error('Error fetching declarations:', errorData.error)
        }
    } catch (error) {
        console.log('Error fetching declarations:', error.message)
    }
},

async updateInvoiceLine(invoiceLine) {
  console.log(invoiceLine.colli)
  try {
    
      const response = await fetch(`${API_BASE_URL}/api/outbound/get-declarations/${this.selectedOutboundId}/invoices/${this.selectedInvoiceId}/invoice-lines/${this.selectedInvoiceLineId} `, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            {
              colli: invoiceLine.colli,
              shared_cost: invoiceLine.shared_cost
            }
          )
      });
      if (response.status === 200) {
        
          // this.purchaseOrders = await response.json();
          // await this.getPurchaseOrders(this.selectedInboundId);
      } else {
          const errorData = await response.json();
          console.error('Error adding declaration:', errorData.error);
      }
  } catch (error) {
      console.log('Error adding declaration:', error.message);
  }
},
    
        async addDeclaration(outboundNumber) {
            try {
                const currentDate = new Date();

                // Format the date as yyyy-mm-ddThh:mm:ss
                const formattedDate =
                currentDate.getFullYear() +
                '-' +
                ('0' + (currentDate.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + currentDate.getDate()).slice(-2) +
                'T' +
                ('0' + currentDate.getHours()).slice(-2) +
                ':' +
                ('0' + currentDate.getMinutes()).slice(-2) +
                ':' +
                ('0' + currentDate.getSeconds()).slice(-2);

                const formattedInvoiceDate =
                currentDate.getFullYear() +
                '-' +
                ('0' + (currentDate.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + currentDate.getDate()).slice(-2);

                this.newDeclaration.outbound_number = outboundNumber;
                this.newDeclaration.outbound_date = formattedDate;
                this.newDeclaration.invoice_date = formattedInvoiceDate;
                
                
                
                const response = await fetch(`${API_BASE_URL}/api/outbound/add-declaration`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.newDeclaration)
                });
                if (response.status === 200) {
                    const responseData = await response.json();
                    this.selectedOutboundId = responseData.declaration_id;
                    
                    await this.getOutboundDeclarations();
                } else {
                    const errorData = await response.json();
                    console.error('Error adding declaration:', errorData.error);
                }
            } catch (error) {
                console.log('Error adding declaration:', error.message);
            }
        },

        async deleteInboundDeclaration(declarationId) {
            try {
              const response = await fetch(
                `${API_BASE_URL}/api/inbound/delete-declaration/${declarationId}`,
                {
                  method: 'DELETE',
                  headers: {
                    'Content-Type': 'application/json'
                  }
                }
              )
      
              if (response.status === 200) {
                await this.getInboundDeclarations() // Refresh the list after deleting a declaration
              } else {
                const errorData = await response.json()
                console.error('Error deleting declaration:', errorData.error)
              }
            } catch (error) {
              console.error('Error deleting declaration:', error.message)
            }
          },

          async updateConsignor(tradingPartner) {
            try {
              
                this.editConsignor = tradingPartner
                
                
                const response = await fetch(`${API_BASE_URL}/api/outbound/consignor/${this.selectedOutboundId}/invoices/${this.selectedInvoiceId}`, {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.editConsignor)
                });
                if (response.status === 200) {
                    // this.purchaseOrders = await response.json();
                    
                    // await this.getPurchaseOrders(this.selectedInboundId);
                } else {
                    const errorData = await response.json();
                    console.error('Error adding declaration:', errorData.error);
                }
            } catch (error) {
                console.log('Error adding declaration:', error.message);
            }
        },


          async getTradingPartners() {
            try {
                const response = await fetch(`${API_BASE_URL}/internal/get-trading-partners`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                if (response.status === 200) {
                    this.tradingPartners = await response.json()
                    
                } else {
                    const errorData = await response.json()
                    console.error('Error fetching declarations:', errorData.error)
                }
            } catch (error) {
                console.log('Error fetching declarations:', error.message)
            } 
        },
        
        async addInvoice() {
            
          try {
            console.log('Sending new invoice:', JSON.stringify(this.newInvoice, null, 2));
              const response = await fetch(`${API_BASE_URL}/api/outbound/get-declarations/${this.selectedOutboundId}/invoices`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(this.newInvoice)
              });
              if (response.status === 200) {
                  // this.purchaseOrders = await response.json();
                  
                  await this.getOutboundDeclarationById(this.selectedOutboundId)
              } else {
                  const errorData = await response.json();
                  console.error('Error adding declaration:', errorData.error);
              }
          } catch (error) {
              console.log('Error adding declaration:', error.message);
          } finally {
          }
      },

        async updateInvoice(invoice) {
          try {
            this.editInvoice.invoice_type = invoice.invoice_type
              this.editInvoice.invoice_number = invoice.invoice_number
              this.editInvoice.invoice_date = invoice.invoice_date
              this.editInvoice.invoice_amount = invoice.invoice_amount
              this.editInvoice.invoice_currency = invoice.invoice_currency
              this.editInvoice.delivery_term = invoice.delivery_term

              this.editInvoice.bill_to.id = invoice.bill_to.id
              this.editInvoice.bill_to.name = invoice.bill_to.name
              this.editInvoice.bill_to.address = invoice.bill_to.address
              this.editInvoice.bill_to.zip_code = invoice.bill_to.zip_code
              this.editInvoice.bill_to.zip_place = invoice.bill_to.zip_place
              this.editInvoice.bill_to.international_code = invoice.bill_to.international_code
              this.editInvoice.bill_to.tax_number = invoice.bill_to.tax_number

              this.editInvoice.ship_to.id = invoice.ship_to.id
              this.editInvoice.ship_to.name = invoice.ship_to.name
              this.editInvoice.ship_to.address = invoice.ship_to.address
              this.editInvoice.ship_to.zip_code = invoice.ship_to.zip_code
              this.editInvoice.ship_to.zip_place = invoice.ship_to.zip_place
              this.editInvoice.ship_to.international_code = invoice.ship_to.international_code
              this.editInvoice.ship_to.tax_number = invoice.ship_to.tax_number
              
              console.log(this.editInvoice, "LOG4")
              const response = await fetch(`${API_BASE_URL}/api/outbound/update-invoice/${this.selectedOutboundId}/invoices/${this.selectedInvoiceId}`, {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(this.editInvoice)
              });
              if (response.status === 200) {
                  // this.purchaseOrders = await response.json();
                  
                  await getOutboundDeclarationById(this.selectedOutboundId)
              } else {
                  const errorData = await response.json();
                  console.error('Error adding declaration:', errorData.error);
              }
          } catch (error) {
              console.log('Error adding declaration:', error.message);
          }
      },

      async deleteInvoice() {
        try {
          const response = await fetch(
            `${API_BASE_URL}/api/outbound/get-declarations/${this.selectedOutboundId}/invoices/${this.selectedInvoiceId}`,
            {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
  
          if (response.status === 200) {
            await this.getOutboundDeclarationById(this.selectedOutboundId)
             // Refresh the list after deleting a declaration
          } else {
            const errorData = await response.json()
            console.error('Error deleting declaration:', errorData.error)
          }
        } catch (error) {
          console.error('Error deleting declaration:', error.message)
        }
      },

      async addInvoiceLine() {
            
        try {
            const response = await fetch(`${API_BASE_URL}/api/outbound/add-invoice-line/${this.selectedOutboundId}/invoices/${this.selectedInvoiceId}`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.newInvoiceLine)
            });
            if (response.status === 200) {
                // this.purchaseOrders = await response.json();
                
                await this.getOutboundDeclarationById(this.selectedOutboundId)
            } else {
                const errorData = await response.json();
                console.error('Error adding declaration:', errorData.error);
            }
        } catch (error) {
            console.log('Error adding declaration:', error.message);
        } finally {
        }
    },

    async deleteInvoiceLine() {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/outbound/delete-invoice-line/${this.selectedOutboundId}/invoices/${this.selectedInvoiceId}/invoice-lines/${this.selectedInvoiceLineId}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )

        if (response.status === 200) {
          await this.getOutboundDeclarationById(this.selectedOutboundId)
           // Refresh the list after deleting a declaration
        } else {
          const errorData = await response.json()
          console.error('Error deleting declaration:', errorData.error)
        }
      } catch (error) {
        console.error('Error deleting declaration:', error.message)
      }
    },
      async getInvoiceLines() {
        try {
            const response = await fetch(`${API_BASE_URL}/api/outbound/get-declarations/${this.selectedOutboundId}/invoices/${this.selectedInvoiceId}/invoice-lines`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (response.status === 200) {
                this.invoiceLines = await response.json()
                
            } else {
                const errorData = await response.json()
                console.error('Error fetching declarations:', errorData.error)
            }
        } catch (error) {
            console.log('Error fetching declarations:', error.message)
        }
    },
    async getProducts() {
      try {
          const response = await fetch(`${API_BASE_URL}/api/outbound/get-declarations/${this.selectedOutboundId}/invoices/${this.selectedInvoiceId}/invoice-lines/${this.selectedInvoiceLineId}/products`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json'
              }
          })
          if (response.status === 200) {
              this.products = await response.json()
              
              
          } else {
              const errorData = await response.json()
              console.error('Error fetching declarations:', errorData.error)
          }
      } catch (error) {
          console.log('Error fetching declarations:', error.message)
      }
  },

  async addProduct() {
    
try {
    const response = await fetch(`${API_BASE_URL}/api/outbound/add-product/${this.selectedOutboundId}/${this.selectedInvoiceId}/${this.selectedInvoiceLineId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.newProduct)
    });
    if (response.status === 200) {
        // this.purchaseOrders = await response.json();
        
        await this.getOutboundDeclarationById(this.selectedOutboundId)

    } else {
        const errorData = await response.json();
        console.error('Error adding declaration:', errorData.error);
    }
} catch (error) {
    console.log('Error adding declaration:', error.message);
} finally {
}
},

async deleteProduct() {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/outbound/delete-product/${this.selectedOutboundId}/${this.selectedInvoiceId}/${this.selectedInvoiceLineId}/${this.selectedProductId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )

    if (response.status === 200) {
      this.getOutboundDeclarationById(this.selectedOutboundId)
       // Refresh the list after deleting a declaration
    } else {
      const errorData = await response.json()
      console.error('Error deleting declaration:', errorData.error)
    }
  } catch (error) {
    console.error('Error deleting declaration:', error.message)
  }
},

  async updateProduct(product) {
    try {
      
        this.editProduct = product
        
        
        const response = await fetch(`${API_BASE_URL}/api/outbound/update-product/${this.selectedOutboundId}/invoices/${this.selectedInvoiceId}/invoice-lines/${this.selectedInvoiceLineId}/product/${this.selectedProductId} `, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.editProduct)
        });
        if (response.status === 200) {
            // this.purchaseOrders = await response.json();
            
            // await this.getPurchaseOrders(this.selectedInboundId);
        } else {
            const errorData = await response.json();
            console.error('Error adding declaration:', errorData.error);
        }
    } catch (error) {
        console.log('Error adding declaration:', error.message);
    }
},

async updateBillTo(invoice) {
  try {
    
      const response = await fetch(`${API_BASE_URL}/api/outbound/update-bill-to/${this.selectedOutboundId}/invoices/${this.selectedInvoiceId} `, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(invoice)
      });
      if (response.status === 200) {
          // this.purchaseOrders = await response.json();
          
          // await this.getPurchaseOrders(this.selectedInboundId);
      } else {
          const errorData = await response.json();
          console.error('Error adding declaration:', errorData.error);
      }
  } catch (error) {
      console.log('Error adding declaration:', error.message);
  }
},

async updateShipTo(invoice) {
  try {
    
      const response = await fetch(`${API_BASE_URL}/api/outbound/update-ship-to/${this.selectedOutboundId}/invoices/${this.selectedInvoiceId} `, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(invoice)
      });
      if (response.status === 200) {
          // this.purchaseOrders = await response.json();
          // await this.getPurchaseOrders(this.selectedInboundId);
      } else {
          const errorData = await response.json();
          console.error('Error adding declaration:', errorData.error);
      }
  } catch (error) {
      console.log('Error adding declaration:', error.message);
  }
},



async exportXml(storedEmail) {
  try {
      const response = await fetch(`${API_BASE_URL}/api/outbound/create-xml/${this.selectedOutboundId}/${storedEmail}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json' // Add this header
          },
          body: JSON.stringify(storedEmail) // Send email as JSON body
      });

      if (response.ok) {
          // Additional logic if needed
      } else {
          const errorData = await response.json();
          console.error('Error creating XML:', errorData.detail);
      }
  } catch (error) {
      console.error('Error creating XML:', error.message);
  } finally {
    alert(`XML file for: ${this.selectedOutboundId} sent! `)
  }
}




        
        
    }
})


// router
import { createRouter, createWebHistory } from 'vue-router'
import { useInboundStore } from '@/stores/inboundStore.js'
import { useOutboundStore } from '@/stores/outboundStore.js'
import { useUserStore } from '@/stores/userStore.js'
import { ACCESS_TOKEN_KEY } from '@/stores/userStore.js';
import { NAME } from '@/stores/userStore.js';
import { API_BASE_URL } from '@/stores/userStore.js';
import AppLayout from '../layouts/AppLayout.vue'
import loginLayout from '../layouts/LoginLayout.vue'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import MaintenanceView from '@/views/MaintenanceView.vue';

// const API_BASE_URL = 'http://localhost:8000';
// const API_BASE_URL = 'https://logent-effektivflyt.koyeb.app';

const HEALTH_CHECK_INTERVAL = 60000; // Check server health every 1 minute
let isServerDown = false;

const router = createRouter({
  linkExactActiveClass: "active",
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // New route for the maintenance page
    {
      path: '/maintenance',
      name: 'maintenance',
      component: MaintenanceView, // Import the MaintenanceView component
    },
    {
      path: '/login',
      component: loginLayout,
      meta: { requireAuth: false },
      children: [
        {
          path: '',
          name: 'login',
          component: LoginView
        }
      ]
    },
    {
      path: '/',
      component: AppLayout,
      meta: { requireAuth: true },
      children: [
        {
          path: '',
          name: 'home',
          component: HomeView
        }
      ]
    },
    {
      path: '/about',
      component: AppLayout,
      meta: { requireAuth: true },
      children: [
        {
          path: '',
          name: 'about',
          // route level code-splitting
          // this generates a separate chunk (About.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import('../views/AboutView.vue'),
        }
      ]
    },
    {
      path: '/profile',
      component: AppLayout,
      meta: { requireAuth: true },
      children: [
        {
          path: '',
          name: 'profile',
          component: () => import('../views/profile/ProfileView.vue'),
          children: [
            {
              path: '',
              name: 'profileDefault',
              redirect: { name: 'profileEdit'}
            },
            {
              path: '/edit-profile',
              name: 'profileEdit',
              component: () => import('../views/profile/ProfileEdit.vue')
            }
          ]
        }
      ]
    },
    {
      path: '/customs',
      component: AppLayout,
      meta: { requireAuth: true },
      children: [
        {
          path: '',
          name: 'customs',
          component: () => import('../views/customs/CustomsView.vue'),
          children: [
            {
              path: '/inbound-editor',
              name: 'inboundEditor',
              component: () => import('../views/customs/dk/inbound/editor/InboundEditor.vue'),
              children: [
                {
                  path: '',
                  name: 'inboundEditorDetails',
                  redirect: { name: 'inboundEditorDetails'}
                },
                {
                  path: 'inbound-editor-details',
                  name: 'inboundEditorDetails',
                  component: () => import('../views/customs/dk/inbound/editor/InboundEditorDetails.vue')

                },
                {
                  path: 'document-add',
                  name: 'documentAdd',
                  component: () => import('../views/customs/dk/inbound/editor/DocumentAdd.vue')

                },
                {
                  path: 'xml-document',
                  name: 'xmlDocument',
                  component: () => import('../views/customs/dk/inbound/editor/XmlDocument.vue')

                },
                {
                  path: 'document-history',
                  name: 'documentHistory',
                  component: () => import('../views/customs/dk/inbound/editor/DocumentHistory.vue')

                }
              ]
            },

            {
              path: '/outbound-editor',
              name: 'outboundEditor',
              component: () => import('../views/customs/dk/outbound/editor/OutboundEditor.vue'),
              children: [
                {
                  path: '',
                  name: 'outDocumentDefault',
                  redirect: { name: 'outDocumentHistory'}
                },
                {
                  path: 'out-document-history',
                  name: 'outDocumentHistory',
                  component: () => import('../views/customs/dk/outbound/editor/DocumentHistory.vue')

                },
                {
                  path: 'out-document-edit',
                  name: 'outDocumentEdit',
                  component: () => import('../views/customs/dk/outbound/editor/DocumentEdit.vue'),
                  props: true,

                },
                {
                  path: 'out-xml-document-outbound',
                  name: 'outXmlDocumentOutbound',
                  component: () => import('../views/customs/dk/inbound/editor/XmlDocument.vue')

                },
                {
                  path: 'out-document-history-outbound',
                  name: 'outDocumentHistoryOutbound',
                  component: () => import('../views/customs/dk/outbound/editor/DocumentHistory.vue')

                }
              ]
            },
            {
              path: '/inbound',
              name: 'inbound',
              component: () => import('../views/customs/dk/inbound/InboundView.vue'),
              children: [
                {
                  path: '',
                  name: 'inboundDefault',
                  redirect: { name: 'inboundList'}
                },
                {
                  path: '/inbound-list',
                  name: 'inboundList',
                  component: () => import('../views/customs/dk/inbound/InboundList.vue')
                },
                {
                  path: '/inbound-details/:_id',
                  name: 'inboundDetails',
                  component: () => import('../views/customs/dk/inbound/InboundDetails.vue')
                },
                {
                  path: '/inbound-details-experimental/:_id',
                  name: 'inboundDetailsExperimental',
                  component: () => import('../views/customs/dk/inbound/InboundDetailsExperimental.vue')
                },
              ]
            },
            {
              path: '/outbound',
              name: 'outbound',
              component: () => import('../views/customs/dk/outbound/OutboundView.vue'),
              children: [
                {
                  path: '',
                  name: 'outboundDefault',
                  redirect: { name: 'outboundList'}
                },
                {
                  path: '/outbound-list',
                  name: 'outboundList',
                  component: () => import('../views/customs/dk/outbound/OutboundList.vue')
                },
                {
                  path: '/outbound-details/:_id',
                  name: 'outboundDetails',
                  component: () => import('../views/customs/dk/outbound/OutboundDetails.vue')
                },
              ]
            },
            {
              path: '/entities',
              name: 'entities',
              component: () => import('../views/customs/dk/entities/EntityView.vue'),
              children: [
                {
                  path: '',
                  name: 'entityDefault',
                  redirect: { name: 'entityList'}
                },
                {
                  path: '/entity-list',
                  name: 'entityList',
                  component: () => import('../views/customs/dk/entities/EntityList.vue')
                },
                {
                  path: '/entity-add',
                  name: 'entity-add',
                  component: () => import('../views/customs/dk/entities/EntityAdd.vue')
                },
              ]
            },
            {
              path: '/customer',
              name: 'customer',
              component:() => import('../views/customs/dk/customers/CustomerView.vue'),
              children: [
                {
                  path: '',
                  name: 'CustomerDefault',
                  redirect: { name: 'customerList'}
                },
                {
                  path: '/list',
                  name: 'customerList',
                  component: () => import('../views/customs/dk/customers/CustomerList.vue')
                },
                {
                  path: '/add',
                  name: 'customerAdd',
                  component: () => import('../views/customs/dk/customers/CustomerAdd.vue')
                },
                {
                  path: '/sales-files',
                  name: 'sablesFiles',
                  component: () => import('../views/customs/dk/customers/SalesFilesView.vue'),
                  children: [
                    {
                      path: '',
                      name: 'salesFilesDefault',
                      redirect: { name: 'salesFilesList'}
                    },
                    {
                      path: '/sales-files-list',
                      name: 'salesFilesList',
                      component: () => import('../views/customs/dk/customers/SalesFilesList.vue')
                    },
                    {
                      path: '/details/:customerId/:invoiceNumbers', // Add invoiceNumbers parameter
                      name: 'salesFilesDetails',
                      component: () => import('../views/customs/dk/customers/SalesFilesDetails.vue')
                    },
                    {
                      path: '/article-list', // Add invoiceNumbers parameter
                      name: 'articleList',
                      component: () => import('../views/customs/dk/customers/ArticleList.vue')
                    },

                    
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    // {
    //   path: '/inbound',
    //   component: AppLayout,
    //   meta: { requireAuth: true },
    //   children: [
    //     {
    //       path: '',
    //       name: 'inbound',
    //       // route level code-splitting
    //       // this generates a separate chunk (About.[hash].js) for this route
    //       // which is lazy-loaded when the route is visited.
    //       component: () => import('../views/InboundView.vue'),
    //     },
    //     {
    //       path: '/customerView',
    //       name: 'customerView',
    //       component: () => import('../views/customers/CustomerView.vue')
    //     }
    //   ]
    // },

    
    // {
    //   path: '/outbound-details',
    //   component: AppLayout,
    //   meta: { requireAuth: true },
    //   children: [
    //     {
    //       path: '',
    //       name: 'outboundDetails',
    //       // route level code-splitting
    //       // this generates a separate chunk (About.[hash].js) for this route
    //       // which is lazy-loaded when the route is visited.
    //       component: () => import('../views/OutboundDetails.vue'),
    //     }
    //   ]
    // },
    {
      path: '/admin',
      component: AppLayout,
      meta: { requireAuth: true },
      beforeEnter: (to, from, next) => {
        const storedName = localStorage.getItem(NAME);
        if (storedName === 'Remi') {
          next();
        } else {
          next('/');
        }
      },
      children: [
        {
          path: '',
          name: 'admin',
          // route level code-splitting
          // this generates a separate chunk (About.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import('../views/AdminView.vue'),
          children: [
            {
              path: '/users-add',
              name: 'usersAdd',
              // Assuming UsersView.vue exists and is located in the views directory
              component: () => import('../views/users/UsersAdd.vue'),
            },
            {
              path: '/users-list',
              name: 'usersList',
              // Assuming UsersView.vue exists and is located in the views directory
              component: () => import('../views/users/UsersList.vue'),
            }
          ]
        }
      ]
    }
  ]
})


// Periodically check server health
// setInterval(async () => {
//   await checkAndHandleServerHealth();
// }, HEALTH_CHECK_INTERVAL);
// 
// async function checkAndHandleServerHealth() {
//   const isBackendAccessible = await checkBackendAccessibility();
// 
//   if (!isBackendAccessible) {
//     if (!isServerDown) {
//       isServerDown = true;
//       localStorage.removeItem(ACCESS_TOKEN_KEY);
//       router.push('/maintenance');
//     }
//   } else {
//     if (isServerDown) {
//       isServerDown = false;
//       router.push('/login');
//     }
//   }
// }

// async function checkBackendAccessibility() {
//   try {
//     const response = await fetch(`${API_BASE_URL}/api/internal/healthcheck`);
//     return response.ok;
//   } catch (error) {
//     return false;
//   }
// }


router.beforeEach(async (to, from, next) => {

  // await checkAndHandleServerHealth();

  const userStore = useUserStore();
  const storedToken = localStorage.getItem(ACCESS_TOKEN_KEY);

  if (storedToken && !userStore.accessToken) {
    // If token is stored in localStorage but not in store, initialize the store
    userStore.accessToken = storedToken;
  }

  if (to.meta.requireAuth && !userStore.accessToken) {
    // If the route requires authentication and user is not authenticated, redirect to login
    next('/login');
  } else {
    next(); // Proceed to the requested route
  }
});

// Redirect all unspecified paths
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next('/');
  } else {
    next();
  }
});

// Add navigation guard to call inboundStore.status('Inactive') when leaving '/inbound-details'
// router.beforeEach((to, from, next) => {
//   const name = localStorage.getItem(NAME);
//   const inboundRoute = '/inbound-details';
//   if (from.path === inboundRoute && to.path !== inboundRoute) {
//     // Call inboundStore.status('Inactive') when leaving '/inbound-details'
//     const inboundStore = useInboundStore();
//     inboundStore.updateStatus(name, 'Inactive');
//   }
//   next();
// });

// Add navigation guard to call inboundStore.status('Inactive') when leaving '/inbound-details'
router.beforeEach((to, from, next) => {
  const name = localStorage.getItem(NAME);
  const outboundRoute = '/outbound-details';
  if (from.path === outboundRoute && to.path !== outboundRoute) {
    // Call inboundStore.status('Inactive') when leaving '/inbound-details'
    const outboundStore = useOutboundStore();
    outboundStore.updateStatus(name, 'Inactive');
  }
  next();
});
export default router

import { defineStore } from 'pinia'
import { ref } from 'vue'
import { NAME } from './userStore.js';
import { API_BASE_URL } from './userStore.js';

export const useAppStore = defineStore('appStore', {
    state: () => ({
        navigationItems: ref([]),
        appUpdates: ref([]),
        
    }),
    actions: {
        async getAppUpdates() {
            try {
                const response = await fetch(`${API_BASE_URL}/api/internal/get_app_updates`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                if (response.status == 200) {
                    this.appUpdates = await response.json()
                } else {
                    const errorData = await response.json()
                    console.error('Error fetching tokens', errorData.error)
                }
            } catch (error) {
                console.log('Error fetching tokens:', error.message)
            }
        }
    }
})
// LoginView.vue
<template>
  <div class="flex flex-col items-center" v-if="loading">Logging in. please wait.</div>
  
  <div v-else class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    <div class="flex gap-2 items-center sm:mx-auto sm:w-full sm:max-w-sm">
      <img class="h-6 w-auto" src="@/assets/logent_logo_color_2022.png" alt="Logent">
      <h2 class="-mt-3 text-center text-sm font-semibold leading-9 tracking-tight text-gray-900">CMS</h2>
    </div>

  <div class="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
    <form class="space-y-6" action="#" method="POST">
      <div>
        <label for="email" class="block text-xs font-medium leading-6 text-gray-900">Email address</label>
        <div class="mt-2">
          <input id="email" name="email" type="email" autocomplete="email" required class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-xs sm:leading-6 outline-none" v-model="username">
        </div>
      </div>

      <div>
        <div class="flex items-center justify-between">
          <label for="password" class="block text-xs font-medium leading-6 text-gray-900">Password</label>
          <div class="text-xs">
            <a href="#" class="font-semibold text-rose-600 hover:text-rose-500">Forgot password?</a>
          </div>
        </div>
        <div class="mt-2">
          <input id="password" name="password" type="password" autocomplete="current-password" required class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-xs sm:leading-6 outline-none"  v-model="password">
        </div>
      </div>

      <div>
        <button type="submit" class="flex w-full justify-center rounded-md bg-rose-600 px-3 py-1 text-xs font-semibold leading-6 text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"  @click="handleLogin">Sign in</button>
      </div>
    </form>

    
  </div>


   
  </div>
</template>
  
  <script setup>
  import { onMounted } from 'vue'
  import { useUserStore } from '@/stores/userStore'
  import { storeToRefs } from 'pinia';

  const userStore = useUserStore();
const { username, password, accessToken, loading } = storeToRefs(userStore);
  
  
  
  onMounted(() => {
  userStore.initialize();
});

  const handleLogin = async () => {
    try {
      await userStore.login(username.value, password.value)
      // If login succeeds, you can redirect the user to another route
      // For example:
      // $router.push('/dashboard')
      localStorage.setItem('email', username.value)
    } catch (error) {
      console.error('Error logging in:', error);
      // Handle login error (display error message, etc.)
    }
  }
  </script>
  
  <style scoped>
  /* Add your custom styles here */
  </style>
  
<script setup>
import { RouterLink, RouterView } from 'vue-router'
import { useInboundStore } from './stores/inboundStore';
import { useUserStore } from './stores/userStore';
import { storeToRefs } from 'pinia';


const userStore = useUserStore();

const inboundStore = useInboundStore();
const { selectedInboundId, selectedPurchaseOrderId, selectedPackageId, selectedVariantId } = storeToRefs(inboundStore);
</script>

<template>
    <RouterView />
</template>


